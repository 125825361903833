import React from "react"

import Footer from "../components/footer"
import Header2 from "../components/header2"
import Seo from "../components/seo"

const Contactform = () => {
  return (
    <>
      <Seo title="Contact Us" />
      <Header2 />

      <div className="details" style={{ minHeight: "70vh" }}>
        <div className="" style={{ paddingTop: 50 }} />
        <div className="py-5 contact-us-title d-flex justify-content-center">
          <h1 className="display-1 fw-bold">Contact Us</h1>
        </div>
        <div className="container">
          <div className="py-5">
            <p>
              Superior Mercant is always looking to improve the experience it
              provides to it's customers.
            </p>
            <p>
              We actively check our emails and try to respond as soon as
              possible. If you have any concerns or want to inqure anything.
              Feel free to ask us at the given email.
            </p>
          </div>
          <div className="row mt-5 pt-5">
            <div className="col-xs-12 col-md-6 mb-5 mb-md-0">
              <h1 className="fw-bold">
                Get in <span className="highlight"> Touch</span>
              </h1>
              <h5 className="lead">We will get back to you within 24 hours.</h5>
            </div>
            <div className="col-xs-12 col-md-6">
              <p className="lead text-muted mb-0">Email us at</p>
              <h4>
                <a
                  className="text-dark"
                  href="mailto:info@superiormerchants.com"
                >
                  info@superiormerchants.com
                </a>
              </h4>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Contactform
